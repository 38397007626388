import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Modal as ModalUI, Button, Alert } from "flowbite-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "../api/axios";

export default function GenerateAPIKeyModal({ handleUpdates }) {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [key, setKey] = useState("");
  const [copied, setCopied] = useState(false);
  const [hasGeneratedKey, setHasGeneratedKey] = useState(false);
  const { getToken, logout } = useAuth();
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    const checkExistingKey = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`/profile/key/status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response?.data?.hasKey) {
          setHasGeneratedKey(true);
        }
      } catch (error) {
        console.error("Error checking API key status:", error);
      }
    };

    checkExistingKey();
  }, []);

  const generateAPIKey = async () => {
    setShowError(false);
    setCopied(false);

    try {
      const token = getToken();
      const response = await axios.post(
        `/profile/key`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.apiKey) {
        setKey(response.data.apiKey);
        setHasGeneratedKey(true);
      } else {
        throw new Error("Invalid API Key response");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        console.error("Unauthorized: Invalid Token", error);
        logout();
      } else {
        setShowError(true);
      }
    }
  };

  const handleRegenerate = () => {
    // logic to regenerate API key
    setConfirmText(""); // Clear input after regeneration
  };

  return (
    <div>
      {!hasGeneratedKey && (
        <Button
          size="sm"
          color="success"
          onClick={() => {
            setShowModal(true);
            generateAPIKey();
          }}
        >
          Generate API Key
        </Button>
      )}

      <ModalUI
        show={showModal}
        size="3xl"
        onClose={() => {
          handleUpdates();
          setShowModal(false);
          setCopied(false);
        }}
      >
        <ModalUI.Header>
          {hasGeneratedKey ? "Regenerate API Key" : "Copy Generated API Key"}
        </ModalUI.Header>
        <ModalUI.Body>

        {hasGeneratedKey && (
          <div className="mb-4 flex flex-col gap-3">
            <Alert color="warning">
              <span className="font-medium">Warning!</span> Your previous API key will no longer be valid. Make sure to update your applications with the new key.
            </Alert>

            <div className="flex flex-col gap-2">
              <label htmlFor="confirm-regenerate" className="text-gray-700 dark:text-gray-300 text-sm">
                Type <b>Regenerate API Key</b> to confirm:
              </label>
              <input
                id="confirm-regenerate"
                type="text"
                className="border px-3 py-2 rounded-md w-full text-gray-800 dark:text-white bg-gray-100 dark:bg-gray-700"
                placeholder="Type here..."
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
              />
            </div>

            <Button
              color="success"
              disabled={confirmText !== "Regenerate API Key"}
              onClick={handleRegenerate}
            >
              Regenerate
            </Button>
          </div>
        )}

          {key ? (
            <div className="w-full flex flex-col gap-6 items-center">
              <p className="text-gray-600 dark:text-gray-100 text-center">
                <span className="text-red-600 font-medium">Important:</span> This key will not be shown again. Copy and store it securely.
              </p>
              <div className="flex gap-4 w-full items-center">
                <div className="border rounded-md w-full px-4 py-2 truncate text-center">
                  <p className="text-lg dark:text-white">{key}</p>
                </div>
                <CopyToClipboard text={key} onCopy={() => setCopied(true)}>
                  <Button color={copied ? "success" : "light"}>
                    {copied ? "Copied" : "Copy"}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          ) : (
            !hasGeneratedKey&&showError && (
              <Alert color="failure" className="mt-12">
                <span className="font-medium">Error!</span> Unable to generate API key. Please try again later.
              </Alert>
            )
          )}
        </ModalUI.Body>
      </ModalUI>

      {hasGeneratedKey && (
        <Button
          size="sm"
          className="mt-4"
          onClick={() => {
            setShowModal(true);
            generateAPIKey();
          }}
        >
          Re-generate API Key
        </Button>
      )}
    </div>
  );
}
